@import 'swiper/css/bundle';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global variables. */
:root {
  /* Set sans-serif & mono fonts */
  --sans-font: -apple-system, BlinkMacSystemFont, 'Avenir Next', Avenir,
    'Nimbus Sans L', Roboto, Noto, 'Segoe UI', Arial, Helvetica,
    'Helvetica Neue', sans-serif;
  --mono-font: Consolas, Menlo, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;


  /* Default colors; will be overridden by cms theme settings */
  --background: #FFFFFF;
  --text: #000000;
  --border: #A4A4A4;

  --primary: #654940;
  --secondary:#B4957E;
  --accent1: #123223;
  --accent2: #CB2766;

  --black: #000000;
  --off-black:#252525;
  --dark-gray: #5D5E62;
  --medium-dark-gray: #707070;
  --medium-gray:#5b5d62;
  --gray: #C6C6C7;
  --light-gray: #E8E8E8;
  --off-white: #F9F9F9;
  --white: #FFFFFF;


  --primary20: #E0DBD9;

  --primary-btn-bg-color: #000000;
  --primary-btn-border-color: #000000;
  --primary-btn-text-color: #FFFFFF;
  --primary-btn-hover-bg-color: #212016;
  --primary-btn-hover-border-color: #212016;
  --primary-btn-hover-text-color: #FFFFFF;

  --secondary-btn-bg-color: #F9F9F9;
  --secondary-btn-border-color: #FFFFFF;
  --secondary-btn-text-color: #000000;
  --secondary-btn-hover-bg-color: #FFFFFF;
  --secondary-btn-hover-border-color: #FFFFFF;
  --secondary-btn-hover-text-color: #CB2766;

  --inverse-light-btn-bg-color: transparent;
  --inverse-light-btn-border-color: #FFFFFF;
  --inverse-light-btn-text-color: #FFFFFF;
  --inverse-light-btn-hover-bg-color: #FFFFFF;
  --inverse-light-btn-hover-border-color: #FFFFFF;
  --inverse-light-btn-hover-text-color: #000000;

  --inverse-dark-btn-bg-color: transparent;
  --inverse-dark-btn-border-color: #000000;
  --inverse-dark-btn-text-color: #000000;
  --inverse-dark-btn-hover-bg-color: #000000;
  --inverse-dark-btn-hover-border-color: #000000;
  --inverse-dark-btn-hover-text-color: #FFFFFF;

  /* Breakpoints */
  --xs: 480px;
  --sm: 640px;
  --md: 768px;
  --lg: 1024px;
  --xl: 1280px;
  --2xl: 1536px;

  /* Constants */
  --header-height: 4.5rem;
  --promobar-height: 3rem;
  --sidebar-width: 23.5rem;
  --product-image-aspect-ratio: 2 / 3;
  --content-max-width: 96rem;
}

@layer base {
  * {
    box-sizing: border-box;
  }

  html {
    @apply flex min-h-full w-full flex-col scroll-auto font-sans;
  }

  body {
    @apply text-body m-0 flex min-h-[var(--viewport-height)] w-full flex-col bg-background text-text;
  }

  #__next {
    @apply flex min-h-[var(--viewport-height)] flex-col;
  }

  main {
    @apply flex-1
  }

  h1 {
    @apply text-title-h1;
  }

  h2 {
    @apply text-title-h2;
  }

  h3 {
    @apply text-title-h3;
  }

  h4 {
    @apply text-title-h4;
  }

  h5 {
    @apply text-title-h5;
  }

  h6 {
    @apply text-title-h6;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid var(--black);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }
}

@layer components {
  /* text specific */
  .text-title-h1 {
    @apply
      font-seasons
      font-normal
      text-[54px]
      leading-[4rem] /* line-height 64px */
      tracking-wider /* letter-spacing */
      uppercase
      md:text-[80px]
      md:leading-[5.75rem] /* line-height 92px */
      md:tracking-[.08em]
  }
  .text-title-h2 {
    @apply
      font-sans
      font-semibold
      text-3xl
      leading-[2.75rem]
      tracking-[.04em]
      uppercase
      md:text-[40px]
      md:leading-[3.375rem]
  }
  .text-title-h3 {
    @apply
      font-sans
      font-light
      text-2xl
      uppercase
      md:text-3xl
      md:leading-[2.375rem]
  }
  .text-title-h4 {
    @apply
      font-seasons
      font-light
      text-2xl
      leading-6
      md:text-[28px]
      md:leading-8
  }
  .text-title-h5 {
    @apply
      font-sans
      font-medium
      text-[15px]
      leading-5
      tracking-[.02em]
      uppercase
      md:text-[17px]
      leading-[22px]
  }
  .text-title-h6 {
    @apply
      font-sans
      font-medium
      text-xs
      leading-[1.125rem]
      uppercase
      md:tracking-wide
  }
  .text-title-product {
    @apply
      font-sans
      font-normal
      text-2xl
      tracking-[.02em]
      uppercase
      md:text-[28px]
      md:leading-9
      md:tracking-normal
  }
  .text-body {
    @apply
      !font-sans
      font-normal
      text-[15px]
      leading-5
      md:text-base
      md:leading-[1.375rem]
      md:tracking-[-.01em]
  }
  .text-body-sm {
    @apply
      font-sans
      font-normal
      text-[11px]
      leading-4
      md:text-xs
      md:leading-[1.125rem]
  }
  .text-body-lg {
    @apply
      font-sans
      font-normal
      text-lg
      leading-[1.375rem]
      md:leading-6
  }
  .text-link {
    @apply
      font-medium
      text-base
      leading-[1.375rem]
      uppercase
  }
  .text-link-cta {
    @apply
      font-seasons
      font-bold
      text-xs
      uppercase
      tracking-[.08em]
  }
  .text-caption {
    @apply
      font-sans
      font-medium
      text-xs
      leading-[1.125rem]
      tracking-[.02em]
      uppercase
  }
  .text-label {
    @apply
      font-sans
      font-medium
      text-[10px]
      leading-[1.125rem]
      tracking-wider
      uppercase
  }
  /* these are the same */
  .text-label-sm {
    @apply
    font-sans
    font-medium
    text-[10px]
    leading-[1.125rem]
    tracking-wider
    uppercase
  }
  .text-nav {
    @apply
      font-sans
      font-normal
      text-sm
      leading-[1.375rem]
      /* uppercase */
      /* tracking-wide */
  }
  /* no design for this */
  .text-superheading {
    @apply
      font-sans
      font-normal
      tracking-wide
      uppercase
      text-xs
      lg:text-sm
  }
  /* button specific */
  .btn-text {
    @apply
      font-sans
      font-medium
      text-sm
      leading-5
      tracking-[0.06em]
      uppercase
  }
  .btn {
    @apply
      btn-text
      relative
      m-0
      inline-flex
      h-[3.125rem]
      items-center
      justify-center
      overflow-hidden
      whitespace-nowrap
      rounded-lg
      border
      border-solid
      px-5
      py-3
      text-center
      transition-colors
      disabled:cursor-not-allowed
      disabled:border-border
      disabled:bg-lightGray
      disabled:text-mediumGray
      disabled:hover:border-border
      disabled:hover:bg-lightGray
      disabled:hover:text-mediumGray
      [&>p]:truncate
  }
  .btn-primary {
    @apply
      btn
      border-[var(--primary-btn-border-color)]
      bg-[var(--primary-btn-bg-color)]
      text-[var(--primary-btn-text-color)]
      md:hover:border-[var(--primary-btn-hover-border-color)]
      md:hover:bg-[var(--primary-btn-hover-bg-color)]
      md:hover:text-[var(--primary-btn-hover-text-color)]
  }
  .btn-secondary {
    @apply
      btn
      border-[var(--secondary-btn-border-color)]
      bg-[var(--secondary-btn-bg-color)]
      text-[var(--secondary-btn-text-color)]
      md:hover:border-[var(--secondary-btn-hover-border-color)]
      md:hover:bg-[var(--secondary-btn-hover-bg-color)]
      md:hover:text-[var(--secondary-btn-hover-text-color)]
  }
  .btn-inverse-light {
    @apply
      btn
      border-[var(--inverse-light-btn-border-color)]
      bg-[var(--inverse-light-btn-bg-color)]
      text-[var(--inverse-light-btn-text-color)]
      md:hover:border-[var(--inverse-light-btn-hover-border-color)]
      md:hover:bg-[var(--inverse-light-btn-hover-bg-color)]
      md:hover:text-[var(--inverse-light-btn-hover-text-color)]
  }
  .btn-inverse-dark {
    @apply
      btn
      border-[var(--inverse-dark-btn-border-color)]
      bg-[var(--inverse-dark-btn-bg-color)]
      text-[var(--inverse-dark-btn-text-color)]
      md:hover:border-[var(--inverse-dark-btn-hover-border-color)]
      md:hover:bg-[var(--inverse-dark-btn-hover-bg-color)]
      md:hover:text-[var(--inverse-dark-btn-hover-text-color)]
  }
  .btn-atc {
    @apply
      btn
      border-accent2
      bg-accent2
      text-white
      md:hover:border-[#D55285]
      md:hover:bg-[#D55285]
      md:hover:text-white
  }
  /* underline specific */
  .hover-text-underline {
    @apply
      relative
      inline
      bg-[linear-gradient(currentColor,currentColor)]
      bg-[length:0%_1px]
      bg-left-bottom
      bg-no-repeat
      transition-[background-size]
      duration-200
      focus:bg-[length:100%_1px]
      md:hover:bg-[length:100%_1px]
  }
  .text-underline {
    @apply
      relative
      inline
      bg-[linear-gradient(currentColor,currentColor)]
      bg-[length:100%_1px]
      bg-right-bottom
      bg-no-repeat
      transition-[background-size]
      duration-200
      focus:bg-[length:0%_1px]
      md:hover:bg-[length:0%_1px]
  }
  .text-main-underline {
    @apply
      relative
      inline
      bg-[linear-gradient(currentColor,currentColor)]
      bg-[length:100%_2px]
      bg-right-bottom
      bg-no-repeat
      pb-px
      transition-[background-size]
      duration-200
      focus:bg-[length:0%_2px]
      md:hover:bg-[length:0%_2px]
  }
  /* inputs specific */
  .input-text {
    @apply
      h-12
      w-full
      rounded-lg
      border
      border-border
      py-2.5
      px-5
      text-base
      md:hover:border-darkGray
      disabled:bg-lightGray
  }
  .input-label {
    @apply
      block
      pb-1
      pl-5
      font-sans
      text-2xs
      uppercase
      tracking-wider
      font-medium
  }
  /* swiper specific */
  .swiper-wrapper-center .swiper-wrapper {
    @apply
      flex
      items-center
  }
  .swiper-offset-gradient-270-left {
    background: linear-gradient(270deg, var(--background) 0%, rgba(255, 255, 255, 0) 100%);
    @apply pointer-events-none absolute top-0 left-[-8px] bottom-0 z-10 h-full w-[5.9375rem] -rotate-180 opacity-[60] lg:w-[8.125rem]
  }
  .swiper-offset-gradient-270-right {
    background: linear-gradient(270deg, var(--background) 0%, rgba(255, 255, 255, 0) 100%);
    @apply pointer-events-none absolute top-0 right-[-8px] bottom-0 z-10 h-full w-[5.9375rem] opacity-[60] lg:w-[8.125rem]
  }
  .active-bullet-black .swiper-pagination-bullet-active {
    @apply
      bg-black
  }

  .swiper.swiper-fade .swiper-slide:not(.swiper-slide-active) {
    pointer-events: none !important;
    z-index: -1 !important;
  }
}

@layer utilities {
  /* hide scrollbar */
  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  /* horizontal padding */
  .px-contained {
    @apply
      px-4
      md:px-8
      xl:px-12
  }
  /* horizontal padding on mobile only */
  .max-md-px-contained {
    @apply
      max-md:px-4
  }
  /* horizontal padding on desktop only */
  .md-px-contained {
    @apply
      px-0
      md:px-8
      xl:px-12
  }
  /* horizontal padding on larger desktop only */
  .lg-px-contained {
    @apply
      px-0
      md:px-0
      lg:px-8
      xl:px-12
  }
  /* vertical padding */
  .py-contained {
    @apply
      py-8
      md:py-10
      xl:py-12
  }

  .py-contained-2x {
    @apply
      py-8
      md:py-16
  }
}