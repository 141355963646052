.container {
  position: fixed;
  z-index: 500;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  width: 75%;
  padding: 16px 24px;
  background-color: #1F2937;
  color: #F9FAFB;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  gap: 16px;
  @media screen and (max-width: 767px) {
    width: 90%;
    gap: 12px;
    padding: 16px;
  }
}

.message {
  flex: 1;
  font-size: 14px;
  line-height: 20px;
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
}